word-cloud-div {
    background-color: white;
    display: block;
    text-align: center;
    box-shadow: 2px 2px 10px lightgrey;
    text-shadow: 2px 2px 2px lightgrey;
}

.word-cloud-title {
    color: lightseagreen;
    font-size: 30px;
    text-shadow: 2px 2px 1px lightgrey;
}