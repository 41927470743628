.discussion-button {
    display: grid;
}
.discussion {
    display: grid;
    width: 80%;
    margin: 20px auto;
    padding: 30px 100px;
    background-color: #f3f3f3;
    border-radius: 50px;
    border: #cccccc 2px solid;
}
