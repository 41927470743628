.csvTable {
    border-collapse: collapse;
    margin: 25px auto;
    font-size: 0.9em;
    width: 90%;
    overflow-x: scroll;
    font-family: sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.csvTable thead tr {
    background-color: #016836;
    color: #ffffff;
    text-align: center;
}

.csvTable th, .csvTable td {
    padding: 12px 15px;
    border: 2px solid #6dad8e;
}

.csvTable tbody tr {
    border-bottom: 1px solid #dddddd;
}

.csvTable tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.csvTable tbody tr:last-of-type {
    border-bottom: 2px solid #016836;
}

.csvTable tbody tr.active-row {
    font-weight: bold;
    color: #016836;
}

.csvTable tbody tr td:nth-child(1), .csvTable tbody tr td:nth-child(2) {
    overflow-wrap: anywhere;
}