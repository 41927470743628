.App {
    text-align: center;
    display: grid;
    align-content: center;
  }
  
  pre {
    text-align: left;
    width: 60%;
    margin: 0 auto;
    white-space: pre-wrap;
  }
  
  .App#accessTokenScreen {
    margin: 20px 0;
  }
  
  .accessTokenForm {
    display: grid;
    justify-content: center;
    grid-gap: 10px;
  }
  
  .accessTokenForm input{
    justify-self: center;
    margin-left: 10px;
  }
  
  .accessTokenForm input[type="submit"] {
    width: min-content;
    color: green;
    background-color: transparent;
    border: 1px green solid;
    border-radius: 20px;
    padding: 5px 10px;
  }
  
  .accessTokenForm input[type="submit"]:hover {
    background-color: rgba(144, 238, 144, 0.2);
  }
  
  